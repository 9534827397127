import React from 'react';
import Slider from 'react-slick';
import { Link } from 'gatsby';
import { FormattedIcon } from '@components/icons';
import { RichText } from 'prismic-reactjs';

// styles
import { Container } from '@styles';
import {
  SpecialHeading,
  ClassesSection,
  Card,
  StyledImage,
  BodyWrapper,
  BodyTitle,
  FooterWrapper,
  FooterContent,
} from './styles';

const settings = {
  slidesToShow: 2,
  centerMode: true,
  lazyLoad: true,
  infinite: true,
  dots: true,
  arrows: false,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        dots: true,
        infinite: true,
      },
    },
  ],
};

const Classes = ({ data }) => {
  return (
    <ClassesSection>
      <Container normal>
        <SpecialHeading>Our classes</SpecialHeading>
        <Slider {...settings} centerMode={true}>
          {data.map((reference, i) => (
            <Card key={i}>
              <StyledImage
                src={reference.image && reference.image.url}
                alt={reference.image && reference.image.alt}
              />
              <BodyWrapper>
                <RichText render={reference.title} Component={BodyTitle} />
                <FooterWrapper>
                  <FooterContent>
                    <Link to="/classes">View more</Link>
                    <FormattedIcon name="arrow-right" />
                  </FooterContent>
                </FooterWrapper>
              </BodyWrapper>
            </Card>
          ))}
        </Slider>
      </Container>
    </ClassesSection>
  );
};

export default Classes;
