import styled from 'styled-components';
import { theme, media } from '@styles';

const { colors, fonts } = theme;

export const AboutSection = styled.section`
  width: 100%;
  height: auto;
  padding: 3rem 0;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba(10, 13, 27, 0.75);
  ${media.desktop`box-shadow: inset 0 0 0 2000px rgba(10, 13, 27, 0.9);`};
`;

const BaseCol = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 7rem;
  ${media.desktop`
    padding: 4rem;
    text-align: center;
  `}
  ${media.tablet`padding: 0;`};
`;

export const TextCol = styled(BaseCol)`
  flex: 0 0 50%;
  max-width: 50%;

  h3 {
    margin: 0;
  }

  ${media.desktop`
    flex: 0 0 100%;
    max-width: 100%;
  `};
`;

export const Title = styled.h3`
  font-family: ${fonts.Secondary};
  font-weight: 400;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  word-break: break-word;
  font-weight: 400;
  letter-spacing: 0.08em;
  line-height: 1;
  padding-bottom: 32px;
  position: relative;
  color: ${colors.text};

  :after {
    content: '';
    background-color: ${colors.accent};
    transform: translateX(0);
    left: 0;
    bottom: 20%;
    height: 3px;
    left: 0;
    position: absolute;
    width: 50px;

    ${media.desktop`left: 45%;`};
  }
`;

export const Description = styled.p`
  font-size: 16px;
  color: ${colors.text};
`;

export const Name = styled.h5`
  font-family: ${fonts.Secondary};
  font-weight: 400;
  letter-spacing: 0.05em;
  margin-bottom: -4px;
  text-transform: none;
  color: ${colors.text};
  margin-bottom: -4px;
  text-transform: none;
  word-break: break-word;
`;
