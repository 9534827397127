import React from 'react';
import { RichText } from 'prismic-reactjs';

// styles
import { Container } from '@styles';
import { StyledSpan } from '../contact/styles';
import { AboutSection, Title, Description, Row, TextCol, Name } from './styles';

const About = ({ data }) => {
  return (
    <AboutSection
      style={{
        backgroundImage: `url(${data.image && data.image.url})`,
      }}
    >
      <Container normal>
        <Row>
          <TextCol>
            <RichText render={data.title} Component={Title} />
            <RichText render={data.text} Component={Description} />
            <Name>{data.name}</Name>
            <StyledSpan>{data.position}</StyledSpan>
          </TextCol>
        </Row>
      </Container>
    </AboutSection>
  );
};

export default About;
