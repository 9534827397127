import React from 'react';
import { graphql } from 'gatsby';
import {
  Hero,
  Contact,
  About,
  Classes,
  Video,
  Testimonials,
} from '@views/home';
import '@components/slick.css';
import '@components/slick-theme.css';

const RenderBody = ({ home, programs }) => {
  const slider = home.slider;
  const contact = home.contact;
  const about = home.about[0];
  const pArray = programs.classes;
  const testimonials = home.testimonials;

  return (
    <>
      <Hero data={slider} />
      <Contact data={contact} />
      <About data={about} />
      <Classes data={pArray} />
      <Video />
      <Testimonials data={testimonials} />
    </>
  );
};

export default ({ data }) => {
  const doc = data.prismic.allHomepages.edges.slice(0, 1).pop();
  const programs = data.prismic.allClassess.edges.slice(0, 1).pop();

  if (!doc) return null;
  return <RenderBody home={doc.node} programs={programs.node} />;
};

export const query = graphql`
  {
    prismic {
      allClassess {
        edges {
          node {
            classes {
              image
              title
              text
              bottom_text
            }
          }
        }
      }
      allHomepages {
        edges {
          node {
            slider {
              image
              title
              subtitle
              link
              url {
                ...on PRISMIC__ExternalLink {
                  url
                }
              }
            }
            contact {
              title
              subtitle
            }
            about {
              image
              title
              text
              name
              position
            }
            testimonials {
              name
              position
              quote
            }
          }
        }
      }
    }
  }
`;
