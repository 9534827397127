import React from 'react';
import Slider from 'react-slick';
import { FormattedIcon } from '@components/icons';
import { RichText } from 'prismic-reactjs';

// styles
import { Container } from '@styles';
import { SpecialHeading } from '../classes/styles';
import { StyledSpan } from '../contact/styles';
import {
  TestimonialsSection,
  Card,
  HeaderWrapper,
  HeaderName,
  BodyWrapper,
  BodyQuote,
  FooterWrapper,
} from './styles';

const Testimonials = ({ data }) => {
  return (
    <TestimonialsSection>
      <Container normal>
        <SpecialHeading>What our clients are saying</SpecialHeading>
        <Slider lazyLoad={true} infinite={true} dots={true} arrows={false}>
          {data.map((reference, i) => (
            <Card key={i}>
              <HeaderWrapper>
                <RichText render={reference.name} Component={HeaderName} />
                <StyledSpan>{reference.position}</StyledSpan>
              </HeaderWrapper>
              <BodyWrapper>
                <RichText render={reference.quote} Component={BodyQuote} />
              </BodyWrapper>
              <FooterWrapper>
                <FormattedIcon name="quote" />
              </FooterWrapper>
            </Card>
          ))}
        </Slider>
      </Container>
    </TestimonialsSection>
  );
};

export default Testimonials;
