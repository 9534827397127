import styled from 'styled-components';
import { media } from '@styles';

export const VideoSection = styled.section`
  width: 100%;
  height: auto;
  padding: 3rem 0;
`;

export const EmbededWrapper = styled.div`
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
`;

export const StyledEmbed = styled.iframe`
  width: 100%;
  height: 600px;
  ${media.giant`height: 500px;`};
  ${media.thone`height: 400px;`};
`;
