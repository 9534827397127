import React from 'react';
import { css } from 'styled-components';
import Slider from 'react-slick';
import { SecondaryButton } from '@components/button';
import { RichText } from 'prismic-reactjs';

// styles
import { Container } from '@styles';
import {
  StyledCarousel,
  Slide,
  ContentContainer,
  Title,
  Description,
} from './styles';

const Hero = ({ data }) => {
  return (
    <StyledCarousel>
      <Slider
        dots={false}
        arrows={false}
        infinite={true}
        slidesToShow={1}
        autoplay={true}
        lazyLoad={true}
      >
        {data.map((reference, i) => (
          <Slide
            key={i}
            css={css`
              background-image: url(${reference.image && reference.image.url});
            `}
          >
            <Container normal>
              <ContentContainer>
                <RichText render={reference.title} Component={Title} />
                <RichText render={reference.subtitle} Component={Description} />
                {reference.url === null ? (
                  <SecondaryButton to="/classes">
                    {reference.link}
                  </SecondaryButton>
                ) : (
                  <SecondaryButton href={reference.url.url}>
                    {reference.link}
                  </SecondaryButton>
                )}
              </ContentContainer>
            </Container>
          </Slide>
        ))}
      </Slider>
    </StyledCarousel>
  );
};

export default Hero;
