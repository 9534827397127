import styled from 'styled-components';
import { theme, media } from '@styles';

const { colors } = theme;

export const TestimonialsSection = styled.section`
  width: 100%;
  height: auto;
  padding: 3rem 0;
  background-color: ${colors.dark};

  .slick-slide {
    > div:nth-child(1) {
      margin: 0 6rem;
      ${media.desktop`margin: 0 1rem;`}
    }
  }

  ul {
    margin-top: 2rem !important;
  }

  li {
    button :before {
      color: ${colors.text}!important;
    }
  }
`;

export const Card = styled.div`
  background-color: ${colors.hollow};
  padding: 3rem;
`;

export const HeaderWrapper = styled.div`
  text-align: center;
`;

export const HeaderName = styled.div`
  color: ${colors.text};
`;

export const HeaderSub = styled.p`
  color: ${colors.accent};
`;

export const BodyWrapper = styled.div`
  text-align: center;
`;

export const BodyQuote = styled.blockquote`
  text-align: center;
  color: ${colors.light};
`;

export const FooterWrapper = styled.div`
  grid-area: footer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  svg {
    width: 2.5em;
    color: ${colors.accent};
  }
`;
