import styled from 'styled-components';
import { theme, media } from '@styles';

const { colors, fonts } = theme;

export const ContactSection = styled.section`
  width: 100%;
  height: auto;
  padding: 4rem 0;

  ${media.tablet`padding: 2rem 0;`}
`;

export const ContactWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  text-align: center;

  ${media.tablet`
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
  `}

  h5 {
    font-family: ${fonts.Secondary};
    font-weight: 400;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    word-break: break-word;
    line-height: 1;
    margin: 0;
    font-size: 24px;
    color: ${colors.text};
  }
`;

export const IconBox = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${media.tablet`
    :not(:first-child) {
      margin-top: 2.5rem;
    }
  `};
`;

export const IconWrapper = styled.div`
  color: #fff;
  font-size: 100px;
  opacity: 0.1;
  z-index: 0;
  position: absolute;
  left: 40%;

  ${media.desktop`left: 32%;`};
  ${media.tablet`left: 41%;`};

  svg {
    width: 0.8em;
    display: inline-block;
    font-size: inherit;
    text-rendering: auto;

    ${media.phablet`width: 0.6em;`};
  }
`;

export const StyledSpan = styled.span`
  color: ${colors.accent};
  font-family: ${fonts.Secondary};
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 16px;
  text-transform: uppercase;
`;
