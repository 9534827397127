import React from 'react';
import { FormattedIcon } from '@components/icons';
import { RichText } from 'prismic-reactjs';

// styles
import { Container } from '@styles';
import {
  ContactSection,
  ContactWrapper,
  IconBox,
  IconWrapper,
  StyledSpan,
} from './styles';

const Contact = ({ data }) => {
  return (
    <ContactSection>
      <Container normal>
        <ContactWrapper>
          {data.map((reference, i) => (
            <IconBox key={i}>
              <IconWrapper>
                <FormattedIcon
                  name={
                    i === 0
                      ? 'pin'
                      : i === 1
                      ? 'clock'
                      : 'mail'
                  }
                />
              </IconWrapper>
              <StyledSpan>{reference.title}</StyledSpan>
              {RichText.render(reference.subtitle)}
            </IconBox>
          ))}
        </ContactWrapper>
      </Container>
    </ContactSection>
  );
};

export default Contact;
