import styled from 'styled-components';
import { theme, media } from '@styles';

const { colors, fonts } = theme;

export const StyledCarousel = styled.section`
  position: relative;
  display: block;
  align-items: center;
  width: 100vw;
  backface-visibility: hidden;
`;

export const Slide = styled.div`
  height: auto;
  width: 100%;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
`;

export const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  min-height: 70vh;
  justify-content: center;
  flex-direction: column;
  vertical-align: middle;
  margin: 3rem 0;
`;

export const Title = styled.h2`
  font-family: ${fonts.Secondary};
  color: ${colors.text};
  font-size: 48px;
  font-weight: 400;
  letter-spacing: 2.4px;
  line-height: 48px;
  margin-bottom: 40px;

  ${media.desktop`font-size: 30px;`};
  ${media.tiny`
    font-size: 20px;
    text-align: left;
    margin-top: 10px;
    line-height: 1.1;
    margin-bottom: 20px;
    white-space: nowrap;
  `};
`;

export const Description = styled.p`
  color: ${colors.text};
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 2.6em;
  margin-top: 0;
  text-align: left;

  ${media.desktop`
    font-size: 16px;
    margin-bottom: 1em;
  `};
`;
