import React from 'react';

// styles
import { Container } from '@styles';
import { SpecialHeading } from '../classes/styles';
import { VideoSection, EmbededWrapper, StyledEmbed } from './styles';

const Video = () => {
  return (
    <VideoSection>
      <Container normal>
        <SpecialHeading>Fencing at Dynamo</SpecialHeading>
        <EmbededWrapper>
          <StyledEmbed
            title="video"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/18xsfs8Aw_U"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </EmbededWrapper>
      </Container>
    </VideoSection>
  );
};

export default Video;
